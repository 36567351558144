import React from "react";
import "./NotFound.css";

function NotFound() {
  return (
    <div className="not-found">
      <p>....- ----- ....- / -. --- - / ..-. --- ..- -. -..</p>
    </div>
  );
}

export default NotFound;
